<template>
  <div class="textarea__container">
    <textarea
      name="textarea"
      id="textarea"
      cols="80"
      rows="10"
      :placeholder="placeholder"
      :class="{ main: type === 'main', white: type === 'white' }"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: "TextAreaComponent",
  props: ["type", "placeholder"],
};
</script>

<style scoped>
.textarea__container {
  text-align: center;
}
.textarea__container textarea {
  border: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #f5f5f5;
  border-bottom: 2px solid #8c8c8c;
  padding: 15px;
}
.textarea__container textarea.white {
  background-color: white;
}
</style>

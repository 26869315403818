<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TempLeftNav />

    <div class="page">
      <div class="subpage-content">
        <section class="content-band pagehead">
          <div class="pagehead-title">
            <h1>Text Area</h1>
            <div class="pagehead-link">
              <a href=""
                ><img src="/html-components/images/svg/link.svg" alt=""
              /></a>
            </div>
          </div>
          <p>
            A text area is a multi-line form element.
          </p>
        </section>

        <div class="divider w1200"></div>

        <section>
          <div class="content-band">
            <h2>General Guidelines</h2>
            <ul class="bullet-red">
              <li>
                A textarea is for field entries greater than 3 words. For
                entries less than three words a text input field is more
                appropriate.
              </li>
              <li>
                A textarea should be rarely used, it is appropriate for
                long-form comments, feedback or instructions.
              </li>
              <li>
                In general a textarea should not be used for the entry of lists.
                A preferred pattern would be to allow each item to be
                individually entered.
              </li>
            </ul>
          </div>
        </section>

        <div class="divider"></div>

        <section>
          <div class="content-band">
            <h3>Text Area</h3>
            <CodeView :component="primary.main">
              <TextAreaComponent type="primary" placeholder="Text input" />
            </CodeView>
            <CodeView :component="primary.white" class="codeview--gray">
              <TextAreaComponent type="white" placeholder="Text input" />
            </CodeView>
          </div>
        </section>
      </div>

      <SubFooter />
    </div>
  </div>
</template>

<script>
// import StickyTOC from "../StickyTOC";
import TextAreaComponent from "../TextAreaComponent";
import CodeView from "../CodeView";
import TempLeftNav from "../TempLeftNav";
import SubFooter from "../../components/SubFooter";
export default {
  name: "TextArea",
  data: function() {
    return {
      primary: {
        main: {
          codeHTML:
            "<textarea name='textarea' id='textarea' cols='80' rows='10' placeholder='Text input'></textarea>",
          codeCSS: `textarea {
                      border: none;
                      border-top-left-radius: 4px;
                      border-top-right-radius: 4px;
                      background-color: #f5f5f5;
                      border-bottom: 2px solid #0080d9;
                      padding: 15px;
                    }`,
          codeJS: "// No JS Needed",
          preview: "This is the preview",
          name: "Text Area",
        },
        white: {
          codeHTML:
            "<textarea name='textarea' id='textarea' cols='80' rows='10' placeholder='Text input'></textarea>",
          codeCSS: `           .button { 
                      padding: 12px 25px; 
                      width: 140px; 
                      color: white; 
                      background-color: #1a5192; 
                      text-align: center; 
                      border-radius: 20px; 
                      border: none; 
                      font-size: 1rem; 
                      cursor: pointer;
                    }`,
          codeJS:
            "document.getElementById('button-js--primary').addEventListener('click', ()=> {console.log('Hello, world!')});",
          preview: "This is the preview",
          name: "Text Area",
        },
        disabled: {},
      },
      secondary: {
        main: {
          name: "Secondary Button",
          codeHTML:
            "<button id='button-js--secondary' class='button button--secondary'>Button</button>",
          codeCSS: `           .button--secondary { 
                      padding: 12px 25px; 
                      width: 140px; 
                      color: white; 
                      background-color: white; 
                      text-align: center; 
                      border-radius: 20px; 
                      border: none; 
                      font-size: 1rem; 
                      cursor: pointer;
                      border: 3px solid #1a5192;
                      color: #1a5192;
                    }`,
          codeJS: `document.getElementById('button-js--secondary')
            .addEventListener('click', ()=> {console.log('Hello, world!')});`,
        },
        mainWithIcon: {},
        disabled: {},
      },
      tertiary: {
        main: {},
        mainWithIcon: {},
        disabled: {},
      },
      text: {
        main: {},
        mainWithIcon: {},
        disabled: {},
      },
    };
  },
  components: {
    CodeView,
    TextAreaComponent,
    TempLeftNav,
    SubFooter,
    // StickyTOC,
  },
};
</script>
